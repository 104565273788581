import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Script } from 'gatsby';
import { Helmet } from 'react-helmet';
import useMediaQuery from '~hooks/useMediaQuery';
import ConsentContext from '~contexts/Consent';
import { LanguageProvider } from '~contexts/Language';
import { setUserProperties } from '~utils/intercom';
import { CONSUMER_URLS } from '~utils/hello';
import { CMS_URLS } from '~utils/consts';

import '~styles/global.css';

import {
	NOVA_ANALYTICS_URL,
	GTM_CONTAINER_ID,
	GA_MEASUREMENT_ID,
	DEFAULT_GTM_CONSENTS,
	USERLEAP_ID,
	COOKIEBOT_GTM_TYPE_MAPPING,
	ENV,
} from '~utils/consts';
import { setNovaCookie, getNovaCookie, COOKIES, onConsentUpdate } from '~utils/novaCookies';
import { shallowMergeUrlParams, getUtmParameters } from '~utils/urls';
import CookiebotScript from '~globals/Cookiebot/CookiebotScript';

const consumerBaseURL = CONSUMER_URLS[ENV];
const cmsBaseURL = CMS_URLS[ENV];
const FAVICON_DIMENSIONS = [
	16, // Standard for most desktop browsers
	32, // Standard for most desktop browsers
	48, // Google Search Console Guidelines
	96, // Google Search Console Guidelines
	128, // Chrome Web Store icon & Small Windows 8 Star Screen Icon*
	180, // iOS preferred
	192, // Google Developer Web App Manifest Recommendation
];

const RootWrap = ({ children }) => {
	const [isConsentReady, setIsConsentReady] = useState(false);
	const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
	const faviconColor = isDarkTheme ? 'white' : 'black';

	useEffect(() => {
		window.addEventListener('CookiebotOnConsentReady', () => {
			// Read what consents have been set by the user
			const consent = window.Cookiebot.consent;

			// Make a call to update Google Consent mode
			function gtm() {
				window.dataLayerGTM.push(arguments);
			}

			const updatedGoogleConsents = { ...DEFAULT_GTM_CONSENTS };

			Object.entries(consent).forEach(([cookieBotConsentType, isGranted]) => {
				const googleType = COOKIEBOT_GTM_TYPE_MAPPING[cookieBotConsentType];
				updatedGoogleConsents[googleType] = isGranted ? 'granted' : 'denied';
			});

			gtm('consent', 'update', updatedGoogleConsents);

			if (updatedGoogleConsents.ad_storage === 'granted') {
				gtm('set', 'ads_data_redaction', false);
			}

			// Send the event JUST to GTM
			window.dataLayerGTM.push({
				event: 'Cookie Consent Ready',
				appName: 'cms',
				category: 'Consumer',
				ccpaApplies: window.Cookiebot.regulations.ccpaApplies,
				gdprApplies: window.Cookiebot.regulations.gdprApplies,
				marketing: consent.marketing,
				preferences: consent.preferences,
				statistics: consent.statistics,
			});

			// TODO: remove the try/catch wrapper once the `client-analytics` update
			// to expose an `onConsentUpdate` method is deployed.
			try {
				window.NovaAnalytics.onConsentUpdate(consent);
				onConsentUpdate(consent);
			} catch {
				// no-op
			}

			window.NovaAnalytics.track('COOKIE_CONSENT_READY', {
				ccpaApplies: window.Cookiebot.regulations.ccpaApplies,
				gdprApplies: window.Cookiebot.regulations.gdprApplies,
				marketing: consent.marketing,
				preferences: consent.preferences,
				statistics: consent.statistics,
			});

			if (consent.statistics) {
				// Setup Sprig / UserLeap
				(function (l, e, a, p) {
					window.UserLeap = function () {
						U._queue.push(arguments);
					};
					var U = window.UserLeap;
					U.appId = a;
					U._queue = [];
					a = l.createElement('script');
					a.defer = 1;
					a.src = `${e}?id=${U.appId}`;
					p = l.getElementsByTagName('script')[0];
					p.parentNode.insertBefore(a, p);
				})(document, 'https://cdn.userleap.com/shim.js', USERLEAP_ID[ENV]);
			}

			setIsConsentReady(true);

			if (window.Cookiebot.consented) {
				// Trustpilot has its own cookies so only include if consent is granted
				const trustpilotScript = document.createElement('script');
				trustpilotScript.src =
					'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
				document.body.appendChild(trustpilotScript);
			}

			if (consent.necessary && consent.preferences) {
				// update Intercom user properties
				let userProperties = {};

				const utmParameters = getUtmParameters();
				userProperties = { ...utmParameters };

				const referrerUrl = document.referrer;
				if (
					// not redirected from inside consumer dashboard
					referrerUrl?.indexOf(consumerBaseURL) !== 0 &&
					// not redirected from CMS
					referrerUrl?.indexOf(cmsBaseURL) !== 0
				) {
					userProperties.websiteReferrerUrl = referrerUrl;
				}

				if (Object.keys(userProperties).length) {
					setUserProperties({ userProperties });
				}

				// set user properties on MixPanel
				window.NovaAnalytics.setUserProps(
					{ ...utmParameters },
					{
						FacebookPixel: { skip: true },
						GoogleAnalytics: { skip: true },
						GoogleTagManager: { skip: true },
					},
				);
			}
		});

		window.addEventListener('CookiebotOnDialogInit', () => {
			window.NovaAnalytics.track('COOKIE_CONSENT_DISPLAYED', null);
		});
	}, []);

	useEffect(() => {
		// pull UTM parameters and store them on the UTM_INFO cookie
		const utmParameters = getUtmParameters();
		// merge with previous values and store in cookie for later use
		const existing = qs.parse(getNovaCookie(COOKIES.UTM_INFO));
		const mergedParams = shallowMergeUrlParams(existing, utmParameters);
		const asString = qs.stringify(mergedParams);
		if (asString) {
			setNovaCookie(COOKIES.UTM_INFO, asString);
		}
	}, []);

	return (
		<LanguageProvider>
			<ConsentContext.Provider value={isConsentReady}>
				<Helmet>
					{/* Set up Nova Analytics */}
					<script key="script-nova">
						{`
							var analytics = (window.NovaAnalytics = window.NovaAnalytics || []);
							analytics.calls = [];
							analytics.initialized = false;
							analytics.environment = '${ENV}';
							analytics.track = function (event, properties) {
								analytics.calls.push(['track', event, properties]);
							};
							analytics.trackPage = function (pageName, properties) {
								analytics.calls.push(['trackPage', pageName, properties]);
							};
							analytics.onConsentUpdate = function (consent) {
								analytics.calls.push(['onConsentUpdate', consent]);
							};
							analytics.onConsentUpdate = function (consent) {
								analytics.calls.push(['onConsentUpdate', consent]);
							};
							analytics.setUserProps = function (properties, options) {
								analytics.calls.push(['setUserProps', properties, options]);
							};
						`}
					</script>

					{/* Set up GA and GTM consent defaults */}
					<script key="script-ga-gtm-consent">
						{`
							window.dataLayer = window.dataLayer || []; // GA
							window.dataLayerGTM = window.dataLayerGTM || []; // GTM

							// Set up a gtag function that pushes to both GA and GTM
							function gtag() {
								dataLayer.push(arguments);
								dataLayerGTM.push(arguments);
							}
							gtag("consent", "default", {
								ad_storage: "denied",
								analytics_storage: "denied",
								functionality_storage: "granted",
								personalization_storage: "denied",
								security_storage: "granted",
								wait_for_update: 500,
							});
							gtag("set", "ads_data_redaction", true);
							gtag("set", "url_passthrough", true);
						`}
					</script>

					{/* GTM */}
					<script key="script-gtm">
						{`
							(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayerGTM','${GTM_CONTAINER_ID[ENV]}');
						`}
					</script>

					{/* GA */}
					<script key="script-ga">
						{`
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());

							gtag('config', '${GA_MEASUREMENT_ID[ENV]}');
						`}
					</script>

					<meta name="facebook-domain-verification" content="2peuknrigbsd3r8amtdrqjewuzdzkv" />

					<link rel="icon" type="image/ico" href="/favicon.ico" sizes="48x48" />
					{FAVICON_DIMENSIONS.map((size, index) => (
						<link
							key={index}
							rel="icon"
							type="image/png"
							href={`/icons/favicon-${faviconColor}-${size}.png`}
							sizes={`${size}x${size}`}
						/>
					))}
				</Helmet>
				{children}

				{/* Nova Analytics */}
				<Script src={NOVA_ANALYTICS_URL[ENV]} />
				{/* GA */}
				<Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID[ENV]}`} />
				{/* Cookiebot */}
				<CookiebotScript />
			</ConsentContext.Provider>
		</LanguageProvider>
	);
};

export default RootWrap;
