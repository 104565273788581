import { CONSUMERCORE_URLS, ENV } from '~utils/consts';
import { getOrCreateNovaAnonymousId } from '~utils/novaAnonymousId';

/**
 * Adapter for calling a subset of methods exposed in:
 *
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */

export const subscribeToEmailMarketing = ({ email, location, userProperties = {} }) =>
	new Promise(resolve =>
		fetch(`${CONSUMERCORE_URLS[ENV]}/dashboard/marketing-signup`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				novaAnonymousId: getOrCreateNovaAnonymousId(),
				consentToMarketing: true,
				email,
				subscriptionSourceApp: 'cms',
				subscriptionSourceLocation: location,
				subscriptionSourceURL: window.location.href,
				tags: {
					consumer: true,
					d2cConsumer: true,
				},
				userProperties,
			}),
		})
			.then(response => {
				if (response.status === 200) {
					window.NovaAnalytics.track('EMAIL_SUBSCRIBED', {
						location,
					});
				}
				resolve();
			})
			.catch(error => {
				// If there's an issue, we want to fail nicely
				resolve();
			}),
	);

export const setUserProperties = ({ email, userProperties }) =>
	new Promise(resolve =>
		fetch(`${CONSUMERCORE_URLS[ENV]}/dashboard/intercom/user`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				novaAnonymousId: getOrCreateNovaAnonymousId(),
				email,
				userProperties,
			}),
		}).catch(error => {
			// If there's an issue, we want to fail nicely
			resolve();
		}),
	);
