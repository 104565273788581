import * as uuid from 'uuid';

import { COOKIES, getNovaCookie, setNovaCookie } from './novaCookies';

let novaAnonymousId = '';

export const getOrCreateNovaAnonymousId = () => {
	// Retrieve cached ID if available
	if (novaAnonymousId) {
		// If the cookie wasn't successfuly set previously, try again
		if (!getNovaCookie(COOKIES.ANONYMOUS_ID)) {
			setNovaCookie(COOKIES.ANONYMOUS_ID, novaAnonymousId);
		}

		return novaAnonymousId;
	}

	// retrieve or generate a new anonymous ID
	let anonymousId = getNovaCookie(COOKIES.ANONYMOUS_ID);
	if (!anonymousId) {
		anonymousId = uuid.v4();
		setNovaCookie(COOKIES.ANONYMOUS_ID, anonymousId);
	}

	// Cache the freshly generated ID
	novaAnonymousId = anonymousId;

	return anonymousId;
};
