import React, { useState } from 'react';
import { COUNTRIES, MASKED_PROPERTIES } from '~utils/consts';
import { subscribeToEmailMarketing, setUserProperties } from '~utils/intercom';

import RectangularCTA from '~globals/RectangularCTA';
import FormSucceed from '~globals/FormSucceed';

const FullWidgetForm = () => {
	const [succeed, setSucceed] = useState(false);
	const handleSubmit = event => {
		event.preventDefault();
		const formData = Object.fromEntries(new FormData(event.target));
		setUserProperties({ email: formData.email });
		subscribeToEmailMarketing({
			email: formData.email,
			location: 'Form CTA',
			userProperties: {
				[MASKED_PROPERTIES['hasSSN']]: formData.hasSocialSecurity,
				[MASKED_PROPERTIES['hasUSACreditHistory']]: formData.hasUsCredit,
				[MASKED_PROPERTIES['country']]: formData.country,
				[MASKED_PROPERTIES['hasHomeCreditHistory']]: formData.hasHomeCredit,
			},
		}).then(() => {
			setSucceed(true);
		});
	};

	const labelClass = 'block type-body-1 pt-7 lg:pt-11 pb-3';
	const inputClass =
		'type-body-2 border rounded w-full py-3 px-4 flex items-center justify-between bg-pureWhite lg:max-w-sm';
	const radioClass = 'inline-flex items-center mr-4 type-body-1';

	if (succeed) {
		return <FormSucceed />;
	}
	return (
		<form onSubmit={handleSubmit}>
			<label>
				<span className={labelClass}>Where are you from?</span>
				<select className={inputClass} name="country">
					<option disabled selected value="">
						Select Your Country
					</option>
					{COUNTRIES.map(country => (
						<option key={country.value} value={country.value}>
							{country.name}
						</option>
					))}
					<option value="OTHER">Other</option>
				</select>
			</label>
			<fieldset>
				<legend className={labelClass}>
					Did you have a credit card or loan in your home country?
				</legend>
				<label className={radioClass}>
					<input className="styled-radio" type="radio" name="hasHomeCredit" value="yes" />
					<span className="styled-radio-custom" />
					Yes
				</label>
				<label className={radioClass}>
					<input className="styled-radio" type="radio" name="hasHomeCredit" value="no" />
					<span className="styled-radio-custom" />
					No
				</label>
			</fieldset>
			<fieldset>
				<legend className={labelClass}>
					Do you already have a credit card or loan in the U.S.?
				</legend>
				<label className={radioClass}>
					<input className="styled-radio" type="radio" name="hasUsCredit" value="yes" />
					<span className="styled-radio-custom" />
					Yes
				</label>
				<label className={radioClass}>
					<input className="styled-radio" type="radio" name="hasUsCredit" value="no" />
					<span className="styled-radio-custom" />
					No
				</label>
			</fieldset>
			<label>
				<span className={labelClass}>
					We’ll send a guide with your recommendations to your inbox.
				</span>
				<input
					className={inputClass}
					placeholder="Enter your email address"
					type="email"
					name="email"
					autoComplete="email"
					required
				/>
			</label>
			<RectangularCTA className="w-full lg:w-auto mt-8 lg:mt-14" type="submit" text="Submit" />
		</form>
	);
};

export default FullWidgetForm;
